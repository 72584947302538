.body {
    background-color: #b81b20;
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
   
}

.Login {
    background-color: rgb(255, 255, 255);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 550px;
    height: 470px;
    box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
    border-radius: 5px;
 
}

.Login img {
    margin-top: 30px;
    position: relative;
}

.SubName {
    text-align: center;
    font-size: 14px;
    color: #4f4f4f;
    margin-top: -2px;
}

.Login-form {
    margin-top: 30px;
    position: relative;
    color: #4f4f4f;
    padding: 0 50px;
}

.Login-form p {
    margin: 2px;
    font-size: 16px;
    position: relative;
}

.Login-form input {
    padding: 0 10px;
    width: 95%;
    height: 40px;
    /* background-color: #ebebeb; */
    background-color: rgb(240, 242, 245);
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 3px;
    margin-bottom: 20px;
    margin-top: -3px;
}

.Login-form input[type="text"] {
    font-size: 20px;
    color: #4f4f4f;
    
}

.Login-form input[type="password"] {
    font-size: 20px;
    color: #4f4f4f;
}

input:focus {
    outline: none;
}

.btn-login {
    margin-top: 25px;
    width: 200px;
    height: 45px;
    font-size: 20px;
    color: #ffffff;
    background-color: #d71d24;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 3px;
}

.btn-login:hover {
    background-color: #f3565b;
}

.wrong-message {
    color: #d71d24;
    margin-top: -10px;
    font-size: 18px;
}

.setOverFlow {
    margin-top: 15%;
}

@media only screen and (max-width: 564px) {
    .Login {
        overflow-y: hidden;
        margin-top: 0px;
        height: calc(0px + 600px);
        width: auto;
        box-shadow: none;
        overflow-x: hidden;
    }

    .Login-form input {
        height: 30px;
        width: 90%;
    }

    .Login img {
        margin-top: 100px;
    }

    .Login-form {
        width: auto;
        padding: 0 5px;
    }

    .body {
        background-color: rgb(255, 255, 255);
    }
}

@media only screen and (max-height: 403px) {
    .Login {
        top: 220px;
    }
}
.route-container {
    display: flex;
    width: 100%;
    padding-left: 260px;
    padding-right: 10px;
    padding-top: 60px;
    box-sizing: border-box;
    transition: padding-left 0.2s ease-out;
    z-index: -999;

}

.route-container-map {
    display: flex;
    width: 100%;
    padding-left: 260px;
    height: calc(100% - 100px);
    padding-right: 10px;
    padding-top: 60px;
    box-sizing: border-box;
    transition: padding-left 0.2s ease-out;
    z-index: -999;

}

.rdt_TableCell {
    border-bottom: none !important;
}

.route-backgorund {
    width: 100%;
    height: 100%;
    /* background-color: rgb(236, 236, 236); */
    background-color: rgb(247, 248, 250);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-bottom: 50px;
    border-radius: 5px;
    padding-top: 35px;
    overflow: hidden;

}

.route-item-map {
    margin-top: 15px;
    margin-left: 20px;
    height: calc(100% - 80px);
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 19;
    position: relative;
    overflow-y: hidden;
}

.route-item {
    margin-top: 55px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 22px;
    padding-bottom: auto;
    padding-bottom: 50px;
    font-size: 14px;
    z-index: 19;
    overflow-y: hidden;
    border-radius: 15px;
}

/* .route-item2 {
    margin-top: 55px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 14px;
    z-index: 19;
    overflow-y: hidden;
    height: 100%;

} */

.topic-title {
    margin-left: 30px;
    width: 100%;
    color: #1C1E21;
    font-size: 20px;
    font-weight: 400;
}

.topic-title span:hover {
    text-decoration: underline;
}

.route-container .Search {
    width: auto;
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.10) 2px 2px 2.0px;
    padding: 1px 20px 1px 5px;
    border-radius: 10px;
    border: 1px solid #bebebe;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='16' class='bi bi-search ' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 95% center;
    position: relative;
    margin-bottom: 10px;
}

.route-container .Search::placeholder {
    color: rgba(0, 0, 0, 0.37);
    font-size: 14px;
    left: 5px;
}

.route-container .Search[type="text"] {
    background-color: #ffffff;
}

.add.Search {
    width: 25%;
    height: 20px;
    padding: 1px 20px 1px 5px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.164);
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='16' class='bi bi-search ' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 95% center;
    position: relative;
    margin-bottom: 10px;
}

.btn-add {
    margin-top: 5px;
    position: absolute;
    right: 50px;
    border: none;
    border-radius: 5px;
    /* background-color: #4c51a7ef; */
    background-color: rgb(157, 56, 181);
    color: #ffffff;
    font-size: 15px;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
    transition: 0.2s ease-out;
    width: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    cursor: pointer;
    white-space: nowrap;
}

.edit-btn {
    border: none;
    background-color: rgb(250, 201, 66);
    border-radius: 15px;
    color: white;
    min-width: 80px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    height: 23px;
    width: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.6) 0px 3px 3px -1px;
    cursor: pointer;
}

.setting-btn {
    border: none;
    background-color: rgb(67, 143, 230);
    min-width: 80px;
    border-radius: 15px;
    height: 23px;
    color: white;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.6) 0px 3px 3px -1px;
    cursor: pointer;
}

.setting-btns {
    border: none;
    background-color: rgb(67, 143, 230);
    border-radius: 15px;
    height: 23px;
    color: white;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.6) 0px 3px 3px -1px;
    cursor: pointer;
}

.play-btn {
    min-width: 80px;
    border: none;
    background-color: rgb(180, 180, 180);
    border-radius: 15px;
    height: 23px;
    color: white;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.6) 0px 3px 3px -1px;
    cursor: pointer;
}



.delete-btn {
    border: none;
    background-color: #f32626;
    border-radius: 15px;
    color: white;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    height: 23px;
    min-width: 80px;
    width: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.6) 0px 3px 3px -1px;
    cursor: pointer;
}


.close-btn-alert {
    border: none;
    background-color: rgb(73, 131, 255);
    border-radius: 5px;
    color: white;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
    height: 23px;
    width: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.6) 0px 3px 3px -1px;
    cursor: pointer;
}

.btn-add:hover {
    /* background-color: #4c51a7e3; */

    background-color: rgb(160, 45, 189);
    color: #28eb42;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
    transition: 0.2s ease-out;
}

.setting-btn:hover {
    background-color: #53a8e0;
    transition: 0.2s;
}

.edit-btn:hover {
    background-color: #f5cd67;
    transition: 0.2s;
}

.delete-btn:hover {
    background-color: #f74f4f;
    transition: 0.2s;
}

.bodyModal {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100;
    overflow-y: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.overlayAlert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(253, 255, 125, 0.151);
    justify-content: center;
    align-items: center;
    z-index: 99998;
}

.modals {
    padding-left: 40px;
    padding-right: 40px;
    background-color: rgb(248, 248, 248);
    display: flexbox;
    margin: auto;
    margin-top: 10%;
    width: 500px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 6px 4px;
    border-radius: 10px;
    z-index: 35;
    pointer-events: auto;
    opacity: 1;
}

.erorr-message {
    color: red;
    font-size: 12px;
    margin-left: 10px;
    color: #d71d24;
}

.modals-password .close-modal {
    position: absolute;
    margin-top: -10px;
    margin-left: 250px;
    cursor: pointer;
    z-index: 30;
}

.modals-password {
    padding-left: 30px;
    padding-right: 40px;
    background-color: #e7e7e7;
    z-index: 99;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 6px 4px;
    border-radius: 5px;
    pointer-events: auto;
    color: #000000;
}

.modals-password .Delete-btn {
    background-color: #d71d24;
    color: white;
    border: none;
    height: 25px;
    width: 90px;
    margin-left: 20px;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 15px;
}

.modals-password .Cancel-btn {
    background-color: #a0a0a0;
    color: white;
    border: none;
    border-radius: 15px;
    padding: 6px 0;
    height: 25px;
    width: 90px;
    cursor: pointer;
}

.modals-password .Cancel-btn:hover {
    background-color: #c0c0c0;
}

.modals-password .Delete-btn:hover {
    background-color: #ec3c42;
}

.modals-organization {
    z-index: 9999;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 900px;
    min-width: 865px;
    border-radius: 5px;
    overflow: hidden;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.status-sidebar-addOrganization {
    width: 200px;
    height: 500px;
    background-color: rgb(243, 243, 243);
    position: absolute;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.status-sidebar-addOrganization div {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    white-space: nowrap;
    color: #6e6e6e;
}

.status-sidebar-addOrganization div.active {
    color: rgb(25, 124, 223);
    font-weight: 500;
    background-color: rgb(230, 230, 230);
}

.Warning table {
    width: 100%;
}

.Warning tr {
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 15px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1c7;
}

::-webkit-scrollbar-thumb {
    background: #cacaca;
}

.Router-addOrganization p:nth-child(1) {
    color: rgb(0, 0, 0);
    font-size: 18px;
    margin-bottom: 7px;
    margin-top: 0px;
    font-weight: 700;

}

.menu-bar-addOrganization {
    transition: 0.2s;
    position: relative;
    width: auto;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    background-color: #c42a2a;
    border: none;
    color: #f1f1f1;
    font-size: 18px;
}

.table-container {
    max-height: 100px;
    position: relative;
}

.Router-addOrganization {
    box-sizing: border-box;
    background-color: white;
    margin-left: 200px;
    width: auto;
    border: none;
    height: 500px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.dataTableContainer {
    overflow-y: auto;
    max-height: 370px;
    margin-bottom: 20px;
}

.summary-organization {
    position: absolute;
    overflow-y: auto;
    height: 480px;
}

.dataTableContainer table {
    table-layout: fixed;
}

.dataTableContainer td {
    white-space: nowrap;
}

.btn-addOrg {
    padding-left: 15px;
    padding-right: 15px;
    width: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #ffffff;
}

.btn-addOrg.close {
    background-color: #ffffff;
    color: #f58013;
    border: 1px solid #f58013;
    transition: 0.2s;
}

.btn-addOrg.close:hover {
    background-color: #f58013;
    color: #ffffff;
    transition: 0.2s;
}

.btn-addOrg.next {
    position: absolute;
    right: 20px;
    background-color: #ffffff;
    color: #459bd4;
    border: 1px solid #459bd4;
}

.btn-addOrg.next:hover {
    background-color: #459bd4;
    color: #ffffff;
    transition: 0.2s;
}

.btn-addOrg.back {
    position: absolute;
    right: 125px;
    background-color: #ffffff;
    color: #2196f3;
    border: 1px solid #2196f3;
    transition: 0.2s;
}

.btn-addOrg.back:hover {
    background-color: #2196f3;
    color: #ffffff;
    transition: 0.2s;
}

input::placeholder {
    color: #acacac;
}

.Router-addOrganization input[type="time"] {
    width: 265px;
    height: 25px;
}

.Router-addOrganization input:focus {
    border: 1px solid rgb(41, 97, 250);
}

.input-inline {
    width: 45%;
    height: 20px;
    display: inline-block;
    white-space: nowrap;
    padding-bottom: 15px;
}

.input-inline .input-half {
    width: 60%;
    display: inline-block;
    border: 1px solid rgb(0, 0, 0);
    height: 100%;
}

.input-inline select {
    margin-top: 10px;
    width: 65%;
    height: 25px;
    border: 1px solid rgb(0, 0, 0);
}

.input-oneline select {
    width: 63.5%;
    height: 25px;
    border: 1px solid rgb(0, 0, 0);
}

.alert-input {
    width: 90%;
    margin-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    position: relative;
    background-color: #ffbbbeb7;
    color: rgb(255, 31, 31);
}

.alert-input-sendinfo {
    width: 50%;
    margin-top: 10px;
    padding-bottom: 5px;
    padding: 0 3px;
    border-radius: 5px;
    position: relative;
    background-color: #ffbbbeb7;
    color: rgb(255, 31, 31);
}

.erorr-password {
    color: #d71d24;
    margin-left: 150px;
}

.erorr-password2 {
    color: #d71d24;
    margin-left: 0px;
    font-size: 12px;
}

.input-Full {
    width: 90%;
    display: inline-block;
    height: 20px;
    padding-bottom: 15px;
}

.input-oneline {
    height: 20px;
    width: 420px;
    padding: 6px 0;
}

.input-onelines {
    height: 20px;
    width: 460px;
    padding: 6px 0;
}

.input-onelines input {
    width: 55%;
    border: 1px solid rgb(0, 0, 0);
    height: 20px;
}

.input-oneline input {
    width: 60%;
    border: 1px solid rgb(0, 0, 0);
    height: 20px;
}

.input-oneline .input-half {
    width: 60%;
    border: 1px solid rgb(0, 0, 0);
    height: 100%;
    height: 20px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-center {
    width: 90%;
    height: 20px;
    padding-bottom: 15px;
}

.input-center input {
    margin-top: 15px;
    width: 30%;
    display: inline-block;
    border: 1px solid rgb(0, 0, 0);
    height: 100%;
}

.input-Full textarea {
    width: 80%;
    max-width: 80%;
    min-width: 80%;
    max-height: 30px;
    min-height: 30px;
    display: inline-block;
    border: 1px solid rgb(0, 0, 0);
    overflow: hidden;
}

.modals-password .topic-modal {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.modals-password label {
    font-size: 12px;
}

.modals-password .submit-btn {
    border: none;
    background-color: #d71d24;
    border-radius: 12px;
    color: white;
    font-size: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
}

.loader2 {
    width: 15px;
    height: 15px;
    border: 2px solid #d71d24;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader3 {
    width: 15px;
    height: 15px;
    border: 2px solid #f1f1f1;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modals-password input[type="password"],
.modals-password input[type="text"] {
    width: 100%;
    border: 1px solid rgb(139, 139, 139);
    border-radius: 15px;
    height: 30px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
}

.modals-password input[type="file"] {
    border: 1px solid rgb(139, 139, 139);
    background-color: white;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    padding-left: 10px;
}


.bodyModal input[type="text"],
.bodyModal input[type="password"],
.bodyModal input[type="number"],
.bodyModal input[type="email"],
.bodyModal textarea,
.bodyModal select,
.bodyModal input[type="date"] {
    font-size: 12px;
    background-color: white;
    padding-left: 10px;
    border: 1px solid rgb(139, 139, 139);
    border-radius: 5px;
}

.summary-input-background {
    background-color: #e7e7e7;
    color: #575656;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #2196f3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.Line-input {
    width: 50%;
    border: 1px solid rgb(128, 128, 128);
    height: 25px;
    margin-top: 5px;
    border-radius: 5px;
}

.Line-Btn {
    margin-top: 20px;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* .organization {
    display: flex;
} */

.organization .route-item {
    margin-top: 10px;
}

.organization-setting {
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    position: relative;
}

.organization-setting nav {
    position: relative;
    display: flex;
    text-overflow: ellipsis;
    overflow-x: auto;
    overflow-y: auto;
    max-width: 800px;
    padding-bottom: 15px;
    white-space: nowrap;
    overflow-x: auto;
}

/* .organization-setting nav:hover {
    height: 29.26px;
} */

.organization-menu {
    position: relative;
    color: #00000086;
    padding-top: 5px;
    cursor: pointer;
    margin: 0 15px;
    text-decoration: none;
    font-size: 16px;
}

.organization-menu.active {
    color: #d71d24;
    transition: 0.3s ease-in;
}

.organization-menu:hover {
    color: #d71d24;
    transition: 0.3s ease-in;
}

.organization #marker {
    position: absolute;
    bottom: 7px;
    height: 4px;
    width: 40px;
    left: 15px;
    background-color: #d71d24;
    border-radius: 4px;
    transition: 0.5s;
}

.FixFile {
    display: none;
}


.organization #marker2 {
    position: absolute;
    bottom: 7px;
    height: 4px;
    width: 27%;
    left: 15px;
    background-color: #d71d24;
    border-radius: 4px;
    transition: 0.5s;
}

.file-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
}

.file-input-label {

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    padding: 2px 18px;
    border: 2px solid #1976d2;
    /* Modern border color */
    border-radius: 4px;
    font-size: 16px;
    background-color: #1976d2;
    /* Modern button color */
    color: white;
    /* Text color */
    transition: background-color 0.3s;
}

.file-input-label:hover {
    background-color: #1565c0;
    /* Darker color on hover */
}

/* Style the CloudUploadIcon */
.CloudUploadIcon {
    font-size: 24px;
}


@keyframes fadeAlert {
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fadeAlert2 {
    0% {
        background-color: #ffffff;
        opacity: 0.95;
    }

    50% {
        /* background-color: hsl(44, 92%, 85%); */
        background-color: hsl(0, 0%, 100%);
        opacity: 0.95;
    }

    100% {
        background-color: #ffffff;
        opacity: 0.95;
    }
}

.container-alert-blok {
    max-width: 350px;
    height: auto;
    right: 20px;
    position: absolute;
    z-index: 1002;
    justify-content: center;
    align-items: center;
    display: block;
}

.alert-block:nth-child(1) {
    position: relative;
    margin-top: 65px;
    width: 350px;
    height: auto;
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 25px;
    border-left: 3px solid rgb(252, 55, 55);
    border-bottom: 3px solid rgb(252, 55, 55);
    padding-left: 5px;
    animation: fadeAlert2 0.9s infinite;
    display: block;
}

.alert-block {
    position: relative;
    margin-top: 15px;
    width: 350px;
    height: auto;
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    border-left: 3px solid rgb(252, 55, 55);
    border-bottom: 3px solid rgb(252, 55, 55);
    padding-left: 5px;
    animation: fadeAlert2 0.9s infinite;
    display: block;
}

.close-warning {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.alert-block span {
    position: absolute;
    margin-left: 10px;
    margin-top: 4px;
}

.loader {
    width: 35px;
    height: 35px;
    border: 3px solid #555;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: 25px;
}

.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #d71d24;
}

.AlertEfield {
    position: fixed;
    background-color: #ffffff;
    border-radius: 15px;
    border-bottom: 5px solid rgb(255, 205, 0);
    opacity: 0.9;
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
    width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    z-index: 99999;
    padding-bottom: 15px;
    /* animation: fadeAlert2 0.9s infinite; */
}

.Title-Type-Alert {
    padding-bottom: 5px;
    font-size: 24px;
}

.Detail-Type-Alert {
    padding: 0 20px;
}

.day-selector {
    position: relative;
    margin-bottom: 15px;
    width: 90%;
}

.backOrganizion:hover {
    text-decoration: underline;
    color: rgb(22, 84, 219);
}

.route-container.sidebar-responsive,
.route-container-map.sidebar-responsive {
    padding-left: 10px;
    transition: padding-left 0.2s ease-in;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 738px) {

    .route-container.sidebar-On,
    .route-container-map.sidebar-On {
        padding-left: 10px;
        transition: padding-left 0.2s ease-in;
    }
}

@media only screen and (max-width: 374px) {

    .route-container,
    .route-container-map {
        display: block;
        width: 374px;
    }

    .btn-add {
        position: relative;
        margin-left: 70px;
    }

    .route-item {
        margin-top: 18px;
    }
}

@media only screen and (max-width: 370px) {
    .container-route {
        margin-left: -135px;
    }
}

@media only screen and (max-width: 360px) {
    .container-route {
        margin-left: -140px;
    }
}


.custom-date-picker .MuiInputBase-input {
    height: 5px;
    /* Adjust the height value as needed */
}

.custom-date-picker .MuiSelect-select {
    height: 5px;
}

@media only screen and (max-height: 757px) {
    /* .route-item-map {
        height: 610px;
        min-height: 610px;
    } */

    .route-container-map {
        min-height: 655px;
    }
}

@media only screen and (max-width: 669px) {
    .route-container-map {
        width: 669px;

    }
}
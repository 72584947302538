@import 'https://fonts.googleapis.com/css?family=Kanit|Prompt';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Prompt', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Prompt";
  src: url("https://fonts.googleapis.com/css?family=Kanit|Prompt") format("Prompt"),
    url("https://fonts.googleapis.com/css?family=Kanit|Prompt") format("Prompt");
}

li {
  list-style: none;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

.background-page {
  position: absolute;
  z-index: -990;
  width: 100%;
  height: 100%;
  background-color: rgb(54, 54, 54);
  /* background-color: rgb(95, 95, 95); */
  overflow-y: auto;
  overflow-x: auto;
}

.add-loading {
  font-size: 24px;
  width: 100%;
  height: 100vh;
  margin-top: -8%;
  padding-bottom: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 238, 238);
}

.add-loading img {
  width: 25%;
}

.loaderMain {
  width: 48px;
  height: 48px;
  border: 3px solid #2e2e2e;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.loaderMain::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #ff0000 transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.sidebar {
    position: fixed;
    width: 250px;
    height: 100%;
    background-color: #d71d24;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1001;
    white-space: nowrap;
}

.sidebar .setTopSidebar {
    margin-top: 60px;
}

.sidebar .setTopSidebar2 {
    margin-top: 80px;
}

.sidebar .onSelect:hover {
    background-color: rgba(7, 7, 7, 0.6);
    /* transition: 0.005s ease-in-out ; */
    transition: 0.005s ease-in-out;

}

.sidebar section:hover {
    transition: 0.005s ease-in-out;
    background-color: rgba(7, 7, 7, 0.6);

}

.sidebar-font {
    margin-left: 30px;
    font-size: 16px;
    color: #ffffff;
}

.sidebar .onSelect {
    padding: 10px;
    padding-left: 0px;
    cursor: pointer;
}

.sidebar section {
    padding: 10px;
    padding-left: 0px;
}

.Status-Color {
    background-color: rgba(7, 7, 7, 0.6);
    color: white;
}

.sidebar .active {
    background-color: rgba(7, 7, 7, 0.6);
    box-shadow: inset 6px 0 0 0 rgb(255, 255, 255);
    transition: 0.2s ease-out;
}

.sidebar .active .sidebar-font {
    color: rgb(255, 255, 255);
}

.sidebar section img {
    position: relative;
    left: 100px;
}

.sidebar section .arrowUp img {
    transform: rotate(180deg);
}

.sidebar section .arrowDown img {
    transform: rotate(0deg);
}

.sidebar div div .sidebar-font {
    margin-left: 45px;
}

.sidebar-onload {
    height: 10px;
    margin-left: 25px;
    border-radius: 15px;
    animation: fadeSidebar123 1s infinite;
    margin-top: 30px;
}

.sidebar-onload.ones {
    width: 80%;
}

.sidebar-onload.twos {
    width: 55%;
}

.sidebar-onload.threes {
    width: 35%;
}

.sidebar-onload.fours {
    width: 60%;
}

.sidebar-onload.fives {
    width: 55%;
}

@keyframes fadeSidebar123 {
    0% {
        background-color: white;
        opacity: 1;
    }

    50% {
        background-color: rgb(255, 255, 255);
        opacity: 0.7;
    }

    100% {
        background-color: white;
        opacity: 1;
    }
}

.sidebar-On .sidebar {
    opacity: 0.95;
    transition: max-width 0.2s ease-out;
    max-width: 250px;

}

.sidebar-responsive .sidebar {
    opacity: 0.95;
    max-width: 0px;
    transition: max-width 0.2s ease-in;
}
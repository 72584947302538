.mapView-container {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    box-sizing: border-box;
    z-index: -999;
}

.route-map-backgorund {
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.item-Detail {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    z-index: 1000;
    padding-top: 1%;
}

.E-Field-Area {
    animation: fade 3s infinite;
    z-index: -1;
}

.Circle-info {
    z-index: 1000;
    width: auto;
    height: auto;
    margin-top: 10px;
    cursor: pointer;
}

.Circle-law {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.Circle-law:nth-child(1) {
    border: 3px solid white;
    background-color: rgb(117, 32, 32);

}

.Circle-law:nth-child(2) {
    border: 3px solid white;
    background-color: rgb(117, 65, 35);
}

.Circle-law:nth-child(3) {
    border: 3px solid white;
    /* background-color: rgb(104, 98, 20); */
    background-color: rgb(23, 63, 87);
}

.Circle-law:nth-child(4) {
    border: 3px solid white;
    background-color: rgb(7, 80, 11);
}

.Circle-law.ActiveZoneR {
    background-color: rgb(255, 0, 0);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 20px;
    height: 45px;
    width: 45px;
    animation: flashRed 0.8s infinite;
}

.Circle-law.ActiveZoneA {
    background-color: rgb(250, 99, 29);
    box-shadow: rgba(255, 107, 8, 0.5) 0px 0px 20px;
    height: 45px;
    width: 45px;
    animation: flashOrg 0.8s infinite;

}

.Circle-law.ActiveZoneB {
    /* background-color: rgb(253, 249, 18);
    box-shadow: rgba(255, 252, 46, 0.74) 0px 0px 25px; */

    background-color: rgb(18, 186, 253);
    box-shadow: rgba(18, 186, 253, 0.5) 0px 0px 25px;
    height: 45px;
    width: 45px;
    animation: flashYellow 0.8s infinite;

}

.Circle-law.UnActiveZone {
    background-color: rgb(31, 212, 18);
    box-shadow: rgb(31, 155, 23) 0px 0px 15px;
}

.showDistance.user {
    position: absolute;
    top: 20px;
    left: 180px;
    border-radius: 5px;
    padding: 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: rgba(152, 79, 247, 0.877); */
    background-color: rgba(147, 147, 147, 0.877);
    color: white;
    z-index: 1000;
    font-size: 12px;
    font-weight: 400;
}

.showDistance.admin {
    position: absolute;
    top: 10px;
    left: 200px;
    border-radius: 5px;
    padding: 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgba(147, 147, 147, 0.877);
    /* background-color: rgb(188, 188, 188); */
    color: white;
    z-index: 1001;
    font-size: 12px;
    font-weight: 400;
}

.showDistance.R {
    background-color: rgb(241, 43, 43);
}

.showDistance.A {
    background-color: rgb(250, 99, 29);
}

.showDistance.B {
    background-color: rgb(18, 186, 253);
    /* color: #333; */
    /* background-color: rgba(253, 249, 18, 0.904); */
    font-weight: 400;
}

.AlertAdmin {
    transition: 0.3s ease-in;
    border-radius: 10px;
    padding: 10px;
    color: white;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    justify-items: center;
}

.AlertAdmin.R {
    background-color: rgba(255, 0, 0, 0.781);
    /* animation: flashRedWarn 0.8s infinite; */
}

.AlertAdmin.A {
    background-color: rgba(255, 94, 19, 0.877);
    /* animation: flashOrgWarn 0.8s infinite; */
}

.AlertAdmin.B {
    background-color: rgba(255, 255, 255, 0.767);
    border-bottom: 5px solid rgb(255, 205, 0);
    color: #333;
}

.AlertAdmin.Safe {
    background-color: rgba(255, 255, 255, 0.767);
    border-bottom: 5px solid rgb(82, 228, 56);
    color: #333;
}



@keyframes flashRedWarn {

    0%,
    100% {
        background-color: rgba(255, 0, 0, 0.781);
    }

    50% {
        background-color: rgba(187, 44, 44, 0.74);
    }
}

@keyframes flashOrgWarn {

    0%,
    100% {
        background-color: rgba(255, 94, 19, 0.877);
    }

    50% {
        background-color: rgba(248, 115, 38, 0.849);
    }
}



@keyframes flashRed {

    0%,
    100% {
        background-color: rgb(255, 0, 0);
    }

    50% {
        background-color: rgb(187, 44, 44);
        box-shadow: none;
    }
}

@keyframes flashOrg {

    0%,
    100% {
        background-color: rgb(252, 106, 38);
    }

    50% {
        background-color: rgb(209, 103, 41);
        box-shadow: none;
    }
}

@keyframes flashYellow {

    0%,
    100% {
        /* background-color: rgb(253, 237, 18); */
        background-color: rgb(18, 186, 253);
    }

    50% {
        /* background-color: rgb(219, 203, 55); */
        background-color: rgb(19, 139, 187);
        box-shadow: none;
    }
}

.Detail-block {
    z-index: 1000;
    min-width: 150px;
    max-width: 250px;
    /* width: auto; */
    padding: 0 5px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.562);
    border-radius: 10px;
    overflow: hidden;
    display: block;
    padding-bottom: 10px;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: left 0.2s ease-out;
}



.Detail-Weather {
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    max-width: 340px;
    min-width: 340px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.658);
    border-radius: 15px;
    overflow: hidden;
    /* margin-left: 5px; */
    display: block;
    padding-bottom: 5px;
    position: absolute;
    right: 10px;
    top: 15px;
}

.Detail-block.one {
    top: 65px;
    left: 10px;
}

.Detail-block.two {
    top: 160px;
    left: 10px;
}

.canvasjs-chart-canvas {
    opacity: 1;
}

.white-block-chart {
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: #ffffff;
    opacity: 0.8;
}

.item-chart {
    width: 70%;
    height: 150px;
    position: absolute;
    bottom: 0px;
    left: 15%;
    transition: left 0.25s ease-in-out;
    background-color: rgb(255, 255, 255);
}

.item-chart2 {
    width: 70%;
    height: 150px;
    position: absolute;
    bottom: 0px;
    left: 15%;
    transition: left 0.25s ease-in-out;
}

.Detail-block-chart {
    z-index: 1000;
    width: 100%;
    height: 100%;
    /* border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px; */
    overflow: hidden;
    position: absolute;
    display: block;
    background-color: rgba(255, 255, 255, 0.158);
}

.canvasjs-chart-credit {
    display: none;
}

.white-block {
    white-space: nowrap;
    display: block;
    margin-top: 5px;
    height: 100%;
    padding: 5px 12px;
    border-radius: 10px;
    font-weight: 500;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.733);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 11px;
    overflow: hidden;
}

.white-temp {
    display: inline-block;
    margin-top: 12px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-top: 5px;
    height: 95%;
    width: 34%;
    background-color: rgba(255, 255, 255, 0.762);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 11px;
    border-radius: 13px;
    overflow: hidden;
}

.white-temp2 {
    margin-left: 4px;
    white-space: nowrap;
    display: inline-block;
    margin-top: 12px;
    padding-bottom: 5px;
    padding-top: 5px;
    height: 95%;
    width: 60%;
    background-color: rgba(255, 255, 255, 0.762);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 13px;
    overflow: hidden;
}

.sound-mute {
    position: absolute;
    z-index: 1000;
    left: 20px;
    top: 65px;
    outline: none;
    transition: left 0.2s ease-out;
}

.sound-mute button {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sound-mute.org {
    top: 270px;
}

.white-box1 {
    height: 85px;
    justify-content: center;
    align-items: center;
    display: inline-table;
    padding: 0 5px;
}

.white-box2 {
    padding: 0 5px;
    justify-content: center;
    align-items: center;
    display: inline-table;
}

.white-box3 {
    padding: 0 5px;
    justify-content: center;
    align-items: center;
    display: inline-table;
}

.info-temp {
    font-size: 11px;
}

.info2-detail {
    font-size: 11px;
}

.info-temp3 {
    font-size: 11px;
}


.title-block {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    height: auto;
    background-color: rgba(240, 240, 240, 0.712);
    position: relative;
    white-space: nowrap;
    display: block;
}

.custom-popup {
    background-color: #ffffff;
    color: #333333;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 12px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
}

.popup-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.popup-content {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.popup-label {
    flex: 1;
    font-weight: bold;
    margin-right: 5px;
}

/* .switch-container {
    position: absolute;
    top: 110px;
    z-index: 1000;
    left: 20px;
} */
.popup-Alert p {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.switch-container span {
    background-color: rgb(177, 177, 177);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.switch-container input {
    background-color: rebeccapurple;
}

.optionBoxAdmin {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.877);
    padding: 10px 5px;
    border-radius: 15px;
    z-index: 1000;
    top: 70px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    left: 20px;
}

.sidebar-responsive .goOnOrganization {
    position: absolute;
    z-index: 1000;
    bottom: 150px;
    left: 50%;
    transition: left 0.25s ease-out;
    border: none;
    transform: translate(-50%, -50%);
}

.sidebar-On .goOnOrganization {
    position: absolute;
    z-index: 1000;
    bottom: 150px;
    left: 57.5%;
    border: none;
    transition: left 0.25s ease-out;
    transform: translate(-50%, -50%);
}


@keyframes fade {
    0% {
        fill-opacity: 0.1;
    }

    50% {
        fill-opacity: 0.3;
    }

    100% {
        fill-opacity: 0.1;
    }
}

@media only screen and (min-width: 768px) {
    .sidebar-On .item-chart {
        transition: left 0.2s ease-out;
        left: calc(15% + 140px);
    }

    .sidebar-responsive .Detail-block.one,
    .sidebar-responsive .Detail-block.two {
        left: 10px;
        transition: left 0.3s ease-out;
    }

    .sidebar-responsive .showDistance.user {
        left: 180px;
        transition: left 0.3s ease-out;
    }

    .sidebar-On .showDistance.user {
        left: 440px;
        transition: left 0.3s ease-out;
    }

    .sidebar-responsive .optionBoxAdmin,
    .sidebar-responsive .sound-mute,
    .sidebar-responsive .switch-container {
        left: 20px;
        transition: left 0.3s ease-out;
    }

    .sidebar-On .Detail-block.one,
    .sidebar-On .optionBoxAdmin,
    .sidebar-On .Detail-block.two {
        left: 270px;
        transition: left 0.25s ease-out;
    }

    .sidebar-On .sound-mute,
    .sidebar-On .switch-container {
        left: 280px;
        transition: left 0.25s ease-out;
    }
}


@media only screen and (max-width: 714px) {
    .Detail-Weather {
        max-width: 300px;
        min-width: 110px;
        white-space: nowrap;
        height: auto;
    }

    .white-temp2 {
        height: auto;
    }

    .white-temp2,
    .white-box1,
    .white-box2,
    .white-box3,
    .white-temp {
        display: block;
    }

    .white-box1 {
        height: 65px;
    }

    .white-temp,
    .white-temp2 {
        width: 90%;
        padding-right: 0px;
        margin-left: 0px;
    }

    .item-chart {
        left: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 502px) {
    .showDistance {
        display: none;
    }
}

@media only screen and (max-height: 590px) {
    /* .item-chart {
        height: 100px;
    } */

    .sidebar-responsive .goOnOrganization {
        position: absolute;
        z-index: 1000;
        bottom: 100px;
        left: 50%;
        transition: left 0.25s ease-out;
        border: none;
        transform: translate(-50%, -50%);
    }

    .sidebar-On .goOnOrganization {
        position: absolute;
        z-index: 1000;
        bottom: 100px;
        left: 57.5%;
        border: none;
        transition: left 0.25s ease-out;
        transform: translate(-50%, -50%);
    }
}
.container-Menu {
    width: 100%;
    display: flex;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.MenuBar {
    position: fixed;
    width: 100%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px;
    font-size: 16px;
    color: rgb(78, 75, 75);
    border-top-style: none;
    z-index: 1002;
    justify-content: flex-start;
}

.MenuBar .logo-menu {
    position: absolute;
    top: 10px;
    margin-left: 65px;
}

.MenuBar span {
    position: absolute;
    top: 18px;
    left: 210px;
    color: rgb(78, 75, 75);
    font-size: 14px;
}

.MenuBar .menu-title {
    position: fixed;
    left: 290px;
    margin-top: 13px;
    pointer-events: none;
}

.burger-menu {
    position: fixed;
    top: 10px;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    left: 20px;
    padding: 5px;
    border-radius: 100px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.berger-Active .burger-menu {
    background-color: rgb(245, 245, 245);
}

.burger-menu:hover {
    background-color: rgba(245, 245, 245, 0.534);
}

.burger-bar {
    margin: auto;
    width: 80%;
    height: 2px;
    background-color: #D71D24;
    border-radius: 3px;
}

.setMenuMobile {
    margin-top: 70px;
}

.timeNow {
    position: absolute;
    right: 130px;
    top: 5px;
    font-size: 14px;
    color: rgb(78, 75, 75);
}

.font-container {
    position: fixed;
    width: calc(100% - 550px);
    margin-left: 345px;
    height: 50px;
    overflow: hidden;
    z-index: -1;
    white-space: nowrap;
}

.moving-font {
    color: #D71D24;
    margin-top: 15px;
    font-size: 14px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    animation: moveLeftToRight 25s linear infinite;
}

@keyframes moveLeftToRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.menuburger {
    width: 220px;
    height: 100vh;
    background-color: #D71D24;
    z-index: 2;
    position: fixed;
    display: flex;
    overflow-y: auto;
}

.menuburger:focus {
    outline: none;
}

.menuburger.hidden {
    display: none;
}

.menuburger.visible {
    display: inherit;

}

.setMenuMobile div {
    padding: 6px;
}

.setMenuMobile section {
    padding: 6px;
    cursor: pointer;
}

.setMenuMobile section:hover {
    background-color: rgb(190, 33, 39);
    transition-duration: 0.15s;
}

.setMenuMobile .active {
    border-left: 5px solid rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.4);
}

.setMenuMobile:focus {
    outline: none;
}

.setMenuMobile div:hover {
    background-color: rgb(190, 33, 39);
    transition-duration: 0.15s;
    outline: none;
}

.MenuBar .logout-btn {
    position: fixed;
    right: 15px;
    top: 11px;
    border-radius: 25px;
    font-size: 14px;
    padding: 6px 0;
    padding-right: 40px;
    padding-left: 10px;
    color: rgb(136, 136, 136);
    background-color: rgb(245, 245, 245);
}

.MenuBar .logout-btn:hover {
    cursor: pointer;
    color: rgb(216, 58, 58);
}

.profile-menu {
    width: 170px;
    height: auto;
    background-color: rgb(255, 255, 255);
    position: fixed;
    right: 20px;
    margin-top: 48px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    z-index: 1003;
}

.item-profile {
    align-items: center;
    justify-content: center;

}

.item-profile button {
    background-color: rgb(229, 231, 235);
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 100%;
}

.profile-menu::before {
    content: '';
    position: absolute;
    top: -4.5px;
    right: 8px;
    width: 10px;
    height: 10px;
    background-color: rgb(255, 255, 255);
    transform: rotate(45deg);
}

.profile-menu .item-profile {
    padding: 8px;
    height: auto;
    font-size: 14px;
    color: rgb(0, 0, 0);
    z-index: 999;
    display: block;
    overflow: hidden;
}

.profile-menu .item-profile div {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.profile-menu .item-profile div:hover {
    background-color: rgb(242, 242, 242);
    transition: 0.1s ease;
    border-radius: 8px;
    outline: none;
}

/* .profile-menu .item-profile div:nth-child(1):hover {
    color: rgb(0, 140, 255);
} */

.profile-menu .item-profile div:nth-child(2):hover {
    color: rgb(248, 46, 46);
}

.profile-Active .item-profile,
.profile-Active .profile-menu::before {
    display: inherit;
}

.profile-unActive .item-profile,
.profile-unActive .profile-menu::before {
    display: none;
}

.profile-icon {
    position: absolute;
    display: inline-flex;
    right: 3px;
    top: 2px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

}

.profile-icon img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}

.MenuBar .logo-menu img {
    width: 140px;
    cursor: pointer;
    height: auto;
}



@media only screen and (max-width: 768px) {
    .font-container {
        display: none;
    }

    .MenuBar span {
        display: none;
    }

    .MenuBar .menu-title {
        left: 40px;
        position: relative;
        display: none;
    }

    .MenuBar .background-arrow {
        margin-left: 20px;
    }

    .MenuBar .logo-menu img {
        width: 140px;
    }

    /* .sidebar-font {
        color: rgb(255, 255, 255);
        margin-left: 20px;
        font-size: 14px;
    } */
}

@media only screen and (min-width: 769px) {
    .visible {
        display: none;
    }
}

@media only screen and (max-width: 436px) {
    .timeNow {
        display: none;
    }
}

@media only screen and (max-width: 335px) {
    .logout-btn {
        position: relative;
        left: 213px;
        height: 19px;
        display: flex;
        width: 50px;
    }
}